.product__estimated-delivery {
  &-header {
    margin-top: 0;
  }
  &-preface {
    margin-bottom: 25px;
  }
  &-search {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  &-submit {
    float: right;
  }
  &-data-error {
    margin: 10px 0 20px;
  }
  &-data-table {
    display: none;
    margin: 30px 0;
    width: 100%;
  }
  &-disclaimer {
    display: block;
    margin-top: -1em;
    &-text {
      display: none;
      margin-top: 30px;
    }
  }
}
